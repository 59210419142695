<template>
  <label class="label-group">
    {{ component_meta.label }}
  </label>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  data() {
    return {
      component_id: 'label-group-' + Math.random().toString().substring(2),
      component_meta: this.field,
      component_value: this.value
    }
  }
}
</script>

<style scoped>
label.label-group {
  width: 100%;
  background-color: #727e89;
  border-radius: 3px;
  padding: 6px;
  color: white;
  text-align: center;
}
</style>